







import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import { NoticeType } from '@/types/quiz/notice'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import NoticeQuiz from '@/mixins/quiz/NoticeQuiz'
import NoticeItem from '@/components/molecules/quiz/NoticeItem.vue'
import ModalNotice from '@/components/organisms/quiz/ModalNotice.vue'
import moment from 'moment'

@Component({
  components: {
    NoticeItem,
    ModalNotice,
  },
})
export default class Units extends Mixins(BaseQuiz, NoticeQuiz) {
  @Ref() modalNoticeRef!: ModalNotice

  private notices: NoticeType[] = []

  private notice = {}

  private nonData = false

  private showModal(notice: any) {
    this.notice = notice
    this.modalNoticeRef.show()
  }

  private async loadNotices() {
    this.notices = await this.loadNotifications()
    if (!this.notices.length) {
      this.nonData = true
    }
  }

  private mounted() {
    this.loadNotices()
  }
}
