





import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ButtonLink from '@/components/atoms/quiz/ButtonLink.vue'; 
import { NoticeType } from '@/types/quiz/notice';
import TooltipQuiz from '@/mixins/quiz/Tooltip';

@Component({
  components: {
    ButtonLink,
  },
})
export default class NoticeItem extends Mixins(TooltipQuiz) {
  @Prop({default: {}})
  private notice!: NoticeType

  private onClick() {
    this.$emit('click', this.notice)
  }
}
