import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class NoticeQuiz extends Vue {

  protected markedNotice(noticeId: number) {
    localStorage.setItem('n', JSON.stringify(noticeId))
  }

  protected getNoticeMarked() {
    try {
      const answers = localStorage.getItem('n')
      if (answers) {
        return +JSON.parse(answers)
      }
      return null
    } catch {
      return null
    }
  }

  protected async loadNotifications() {
    const params = ['role=student', 'code=qz']
    const res = await Vue.prototype.$http.httpWithToken.get(`/notifications?${params.join('&')}`)
    return (
      res?.data?.notifications.map((notification: any) => {
        return {
          date: moment(notification.startedAt).format('M/DD(金)HH:mm'),
          title: notification.title,
          id: notification.id,
          message: notification.message,
        }
      }) || []
    )
  }
}
