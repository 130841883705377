








import { Component, Mixins, Prop } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    HeaderModal,
    ModalBase,
  },
})
export default class ModalBorder extends Mixins(ModalBaseMethod) {
  @Prop({default: 300})
  private width!: number

  @Prop({default: 400})
  private height!: number

  @Prop({default: ''})
  private title!: string

  @Prop()
  private close!: () => void

}
