






import { Component, Mixins, Prop } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBorder from '@/components/molecules/quiz/ModalBorder.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import { NoticeType } from '@/types/quiz/notice'

@Component({
  components: {
    HeaderModal,
    ModalBorder,
  },
})
export default class ModalNotice extends Mixins(ModalBaseMethod) {
  @Prop({ default: {} })
  private notice!: NoticeType

  @Prop()
  private openNext?: () => void

  private leave() {
    this.hide()
    this.openNext?.()
  }
}
